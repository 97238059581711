import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  scrollToNextUntested(event) {
    const results = document.querySelectorAll(".test-result");
    const nextUntested = [...results].find((result) => this.statusForResult(result) === "untested");
    if (nextUntested) {
      nextUntested.scrollIntoView();
    } else {
      window.scrollTo(0, document.body.scrollHeight);
    }
    event.preventDefault();
  }

  statusChanged(event) {
    const result = event.target.closest(".test-result");
    this.updateColor(result);
  }

  updateColor(result) {
    result.classList.remove("bg-success-subtle", "bg-danger-subtle", "bg-secondary-subtle");
    const status = this.statusForResult(result);
    if (status === "untested") result.classList.add("bg-secondary-subtle");
    if (status === "failed") result.classList.add("bg-danger-subtle");
    if (status === "passed") result.classList.add("bg-success-subtle");
  }

  statusForResult(result) {
    const steps = result.querySelectorAll(".test-step");
    const status = [...steps].map((step) => {
      return this.statusForStep(step);
    });

    if (status.includes("failed")) {
      return "failed";
    }

    if (status.includes("untested")) {
      return "untested";
    }

    return "passed";
  }

  statusForStep(step) {
    const passed = step.querySelector('input[value="passed"]:checked');
    const failed = step.querySelector('input[value="failed"]:checked');
    const skipped = step.querySelector('input[value="skipped"]:checked');

    if (passed || skipped) {
      return "passed";
    } else if (failed) {
      return "failed";
    } else {
      return "untested";
    }
  }
}
